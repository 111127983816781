<template>
  <div>
    <div class="head">
      <img @click="back" src="@/assets/image/arrow.png" class="arrow_img" />
      <span>ALEO资产明细</span>
    </div>
    <div class="assets">
      <div class="total_box">
        <span>ALEO余额</span>
        <div class="display">
          <span class="total">{{ feeObj.aleo || 0 }}</span>
          <div class="right" @click="withdrawShow = true">
            <div class="btn">提现</div>
          </div>
        </div>
      </div>
      <div class="caption">
        <span>ALEO资产明细</span>
        <van-popover v-model="showPopover" placement="bottom-end" trigger="click" :actions="actions" @select="onSelect">
          <template #reference>
            <span class="right_arrow">{{ typeText || '全部' }}</span>
          </template>
        </van-popover>
      </div>

      <div class="bare" v-if="total < 1">
        <span>暂无数据</span>
      </div>
      <div v-else>
        <!-- <div v-for="(item, index) of 5" :key="index" class="li">
          <div class="li_left">
            <span>直推奖励</span>
            <span class="time">2023-05-09 10:25</span>
          </div>
          <span class="li_right">+245.23</span>
        </div> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div v-for="(item, index) in list" :key="index" class="li">
            <div class="li_left">
              <!-- <span>{{ item.action == 1 ? '租赁算力' : item.action == 2 ? '直推奖励' : item.action == 3 ? '算力奖励' : '平级奖励' }}</span> -->
              <span>{{ typeFormatFn(item.action) }}</span>
              <span class="time">{{ dateFormatFn(item.create_time) }}</span>
            </div>
            <span class="li_right">{{ Math.floor(item.change_amount * 100) / 100 || 0 }}</span>
          </div>
        </van-list>
      </div>
    </div>
    <!-- 提现弹框 -->
    <van-popup v-model="withdrawShow" round style="width: 80%">
      <div class="withdrawPopup">
        <div class="title">
          <span>提现</span>
        </div>
        <div>
          <div>
            <span>提现金额</span>
          </div>
          <div>
            <van-field type="number" v-model="withdrawAmount" placeholder="0" />
          </div>
          <div>
            <span>提现地址</span>
          </div>
          <div>
            <van-field type="textarea" v-model="withdrawAddress" :readonly="Boolean(feeObj.address)" placeholder="请输入地址" />
          </div>
          <div class="tip">
            <span>{{ '最低提现金额：' + feeObj.aleo_min_amount + ' ALEO' }}</span>
            <span style="padding: 6px 0">{{ '提现手续费：' + Number(feeObj.fee_rate) * 100 + '%' }}</span>
            <span>{{ '实际到账：' + receiptAmount + ' ALEO' }}</span>
          </div>
          <div>
            <div class="btn" @click="withdrawSubmit">
              <span>提现</span>
            </div>
          </div>
          <div class="close—icon" @click="withdrawShow = false">
            <van-icon name="close" size="24" color="#C8C9CC" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { homeApi } from '@/api/index';
import { dateFormat, typemat } from '@/utils/formatTool.js';
import Web3 from 'web3';
import { Notify } from 'vant';
export default {
  data() {
    return {
      page: 0,
      loading: false,
      finished: false,
      total: 1,
      list: [],
      actions: [],
      showPopover: false,
      typeText: '全部',
      actionType: -1, //-1:全部
      withdrawShow: false, //提现弹窗状态
      withdrawAmount: 0, // 提现金额
      withdrawAddress: "",// 提现地址
      feeObj: {
        aleo: 0, // 余额
        fee_rate: 0,
        aleo_min_amount: 0,
        address: '', // 提现地址
      },
      receiptAmount: 0, // 实际到账金额
    };
  },
  watch: {
    withdrawAmount(e) {
      // this.receiptAmount = 0;
      this.receiptAmount = Number(e) - Number(e) * Number(this.feeObj.fee_rate);
    },
  },
  mounted() {
    this.getAleoInfo();
    // this.onLoad();
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM-dd hh:mm:ss');
    },
    typeFormatFn(type) {
      
      return this.actions.filter(action => type == action.value)[0]?.text ||"";
    },
    getAleoInfo() {
      homeApi.getAleoInfo({}).then(res => {
        if (res.code == 200) {
          this.feeObj = res.data;
          this.withdrawAddress = res.data.address
          this.actions = []
          Object.entries(res.data.action_list).map(item => {
            this.actions.push({ value: item[0], text: item[1] });
          });
        }
      });
    },
    powerList() {
      let data = {
        page: this.page,
        query: {
          action: this.actionType,
        },
      };
      this.loading = true;
      this.finished = false;
      homeApi.getAleoList(data).then(res => {
        if (res.code == 200) {
          let rows = res.data.rows;
          // 如果返回的数组是空或数组长度是0
          if (rows == null || rows.length === 0) {
            this.loading = false; // 关闭加载状态
            this.finished = true; // 加载结束
            this.total = 0;
            return;
          }
          this.loading = false; // 关闭加载状态
          this.total = res.data.total; //数据总条数
          this.list = this.list.concat(rows); //合并数组
          console.log(this.list);

          // 如果合并之后的数组长度大于返回的数据总条数
          if (this.list.length >= this.total) {
            this.finished = true; // 加载结束
          }
        } else {
          this.total = 0;
          this.loading = false; // 关闭加载状态
          this.finished = true; // 加载结束
        }
      });
    },
    onSelect(action) {
      this.actionType = action.value;
      this.typeText = action.text;
      this.page = 0;
      this.list = [];
      this.onLoad();
    },
    onLoad() {
      this.page += 1;
      this.powerList();
    },
    withdrawSubmit() {
      if (this.withdrawAmount < this.feeObj.aleo_min_amount) {
        Notify({ message: '提现金额不得小于最低提现金额' });
        return;
      }
      if (this.withdrawAddress == '') {
        Notify({ message: '请先填写提现地址' });
        return;
      }
      let web3 = new Web3(window.web3.currentProvider);
      web3.eth.personal.sign(web3.utils.fromUtf8('EtcWithdraw'), this.withdrawAddress, (err, res) => {
        let params = {
          amount: this.withdrawAmount,
          sign: res,
          address: this.withdrawAddress,
        };
        homeApi.aleoSubmit(params).then(res => {
          if (res.code == 200) {
            Notify({ type: 'primary', message: res.msg });
            this.withdrawShow = false;
            this.page = 0
            this.list = []
            this.getAleoInfo();
            this.onLoad();
          } else {
            Notify({ message: res.msg });
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  position: relative;
  display: flex;
  justify-content: center;
  color: #000;
  background: #fff;
  padding: 20px 0 10px;
  .arrow_img {
    position: absolute;
    left: 20px;
    top: 46%;
    width: 10px;
    height: 16px;
    // transform: translate(0, -50%);
  }
}
.assets {
  padding: 20px;
  .total_box {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #fff;
    background: #238be8;
    border-radius: 12px;
    padding: 20px;
    .total {
      font-size: 26px;
      font-weight: bold;
      margin-top: 14px;
    }

    .display {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .btn {
        color: #238be8;
        font-size: 12px;
        padding: 6px 12px;
        border-radius: 10px;
        background-color: #ffffff;
      }
    }
  }
  .caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #238be8;
    font-size: 14px;
    font-weight: bold;
    border-left: 4px solid #238be8;
    padding-left: 6px;
    margin: 20px 0 10px;
    .right_arrow {
      font-size: 12px;
      font-weight: normal;
    }
  }
  .li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    padding: 10px 0;
    .li_left {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: rgba(51, 51, 51, 1);
      .time {
        color: rgba(102, 102, 102, 1);
        margin-top: 4px;
      }
    }
    .li_right {
      font-size: 16px;
      color: #000;
    }
  }
  .bare {
    text-align: center;
    font-size: 14px;
    padding: 60px 14px;
    color: #888;
  }
}
.withdrawPopup {
  padding: 20px 30px;
  color: #666666;
  font-size: 14px;
  .title {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
  }

  .tip {
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }
  .btn {
    color: #fff;
    padding: 16px 0;
    text-align: center;
    line-height: 50%;
    border-radius: 20px;
    margin-top: 18px;
    background-color: #238be8;
  }
  .close—icon {
    position: absolute;
    bottom: -40px;
    left: 45%;
  }
}
/deep/ .van-popup {
  overflow-y: visible;
}

/deep/ .van-field {
  background-color: #f3f9fe;
  border-radius: 8px;
  margin: 6px 0;
}
/deep/ .van-field__body {
  padding: 10px 10px;
}
</style>