import Vue from 'vue';
import VueRouter from 'vue-router';
import { Notify } from 'vant';


import Home from '../views/Home.vue';
import Index from '../views/index/index.vue'; //首页

import miner from '../views/miner/index.vue'; //团队
import lease from '../views/miner/lease.vue'; //算力租赁
import leaseRecord from '../views/miner/leaseRecord/index.vue';  //租赁记录

import bulletin from '../views/bulletin/index.vue'; //公告
import buDetails from '../views/bulletin/details.vue'; //公告详情

import orders from '../views/orders/index.vue';  //我的
import invite from '../views/orders/invite.vue';  //邀请
import withdraw from '../views/orders/withdraw.vue';  //提现
import withdrawRecord from '../views/orders/withdrawRecord.vue';  //提现记录
import assets from '../views/orders/assets.vue';  //资产明细
import introduce from '../views/introduce/index.vue';  //平台介绍
import datainfo from '../views/datainfo/index.vue';  //首页数据页面
import service from '../views/orders/service.vue';  //服务器产品
import aleoAssets from '../views/orders/aleoAssets.vue';  //ALEO资产明细


Vue.use(VueRouter);
const routes = [

  {
    path: '/',
    redirect: '/',
    component: Home,
    children: [
      {
        path: '/',
        name: 'Index',
        component: Index,
      },
      {
        path: 'miner',
        name: 'miner',
        component: miner,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'lease',
        name: 'lease',
        component: lease,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'orders',
        name: 'orders',
        component: orders,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'invite',
        name: 'invite',
        component: invite,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'withdraw',
        name: 'withdraw',
        component: withdraw,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'withdrawRecord',
        name: 'withdrawRecord',
        component: withdrawRecord,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'assets',
        name: 'assets',
        component: assets,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'bulletin',
        name: 'bulletin',
        component: bulletin,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'buDetails',
        name: 'buDetails',
        component: buDetails,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'introduce',
        name: 'introduce',
        component: introduce,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'datainfo',
        name: 'datainfo',
        component: datainfo,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'leaseRecord',
        name: 'leaseRecord',
        component: leaseRecord,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'service',
        name: 'service',
        component: service,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'aleoAssets',
        name: 'aleoAssets',
        component: aleoAssets,
        meta: {
          requiresAuth: true
        }
      },
    ],
  },
];



const router = new VueRouter({
  // mode: 'hash',
  mode: 'history',
  // base: './',
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(localStorage.getItem('parentId'), "kjhjgjkfh");
  let parentId = localStorage.getItem('parentId')
  if (to.meta.requiresAuth) {
    if (parentId != 0) {
      // 判断是否有上级
      next()
    } else {
      next({ path: "/" })
      // return Notify({ type: 'danger', message: '请先绑定上级地址' });
    }
  } else {
    next()
  };
});

export default router;
